<template>
  <div class="ad-protocol">
    <ad-navs :fixed="false"></ad-navs>

    <div class="content-container">
      <h2 class="title">用户使用协议</h2>
      <section class="main">
        <p>更新日期：2021年7月26日</p>
        <p class="indent">
          本协议系您与深圳市云歌人工智能技术有限公司（以下简称“云歌智能”或“我们”）就“灵鸽”软件的账号（以下简称“灵鸽”账号）注册、使用以及“灵鸽”软件服务（以下简称“本服务”）而订立的。云歌智能在此特别提醒您，在注册成为用户（“用户”指注册、登录、使用“灵鸽”账号和本服务的主体）之前，请认真阅读本《用户使用协议》（以下简称“本协议”），确保您充分理解本协议中各条款，并选择接受或不接受本协议，特别是涉及服务费用条款，免除或者限制云歌智能责任的条款，对您的权利限制条款，争议解决和法律适用条款等，此类条款将以加粗的形式提示您注意。如您为无民事行为能力人或为限制民事行为能力人，请告知您的监护人或法定代理人，并在您监护人或法定代理人的陪同和指导下阅读本协议、注册使用“灵鸽”账号和使用本服务。如果您拒绝接受本协议所有条款，您将无权注册、登录或使用本协议所涉服务。如您对本协议内容或页面提示信息有疑问，请勿进行下一步操作。您的注册、登录“灵鸽”账号和使用本服务等行为将视为接受本协议各项条款的约束。

        </p>
        <p class="indent">
          鉴于互联网相关技术、市场、业务模式等等的发展迅速，您同意，云歌智能有权随时根据中华人民共和国有关法律、法规的变化、互联网的发展以及公司经营状况和经营策略的调整等不定时地制定、修改本协议及各类规则，请您关注。如有修订，云歌智能将通过适当的方式公布。经修订的协议、规则一经公布，则立即自动生效，且成为本协议不可分割的一部分。如您不同意上述修订，则您必须立即停止使用本协议项下的各项服务。您继续登录或继续使用本服务，则表明您认可并接受经修订的协议、规则。

        </p>
        <p class=""></p>
        <p class="fw">一、账号注册</p>
        <p class="">
          1、在开始注册程序和使用本服务前，您需确认您具备中华人民共和国法律规定的与您行为相适应的民事行为能力。若您不具备前述与您行为相适应的民事行为能力，则您及您的监护人需承担因此而引起的相应责任及后果。

        </p>
        <p class="">
          2、您在使用本服务前需要注册一个“灵鸽”账号。“灵鸽”账号应当使用手机号码绑定注册，请您使用尚未与“灵鸽”账号绑定的手机号码和未被云歌智能根据本协议封禁的手机号码注册“灵鸽”账号。云歌智能将根据用户需求或产品需要对账号注册和绑定的方式进行变更，变更前已经注册或绑定的账号将不受上述变更的影响。

        </p>
        <p class="">
          3、为了帮助您使用手机号码绑定注册“灵鸽”账号，您同意云歌智能在注册时使用您提供的手机号码等信息用于注册。

        </p>
        <p class="">
          4、基于我们所提供的网络服务的重要性，您确认并同意：提供的注册资料真实、准确、完整、合法有效，注册资料如有变动的，您应通过账号设置页面查询、及时更正您的信息；您可以通过“灵鸽”账号设置页面查询、更正您的信息，您应当通过真实身份信息认证注册账号，且您提交的账号名称、头像和简介等注册信息中不得出现违法和不良信息，经云歌智能审核，如存在上述情况，我们将不予注册；同时，在注册后，如发现您以虚假信息骗取账号名称注册，或您的账号头像、简介等注册信息存在违法和不良信息的，我们有权不经通知单方采取限期改正、暂停使用、终止账号等措施。当您按照注册页面提示填写信息、阅读并同意本协议条款且完成全部注册程序后，您可获得灵鸽账号并成为云歌智能的用户。

        </p>
        <p class=""></p>
        <p class="fw">二、服务内容</p>
        <p class="">
          1、本服务的具体内容由云歌智能根据实际情况提供，包括但不限于授权用户通过其账号进行即时通讯、添加好友、加入群组、关注他人、发布留言等。云歌智能将不定期根据客户需求和反馈对服务内容予以变更，并将变更内容通过适当形式通知用户。

        </p>
        <p class="">
          2、云歌智能提供的服务分为免费服务与日后可能开放的收费服务。用户可以通过网上银行、支付宝或其他“灵鸽”指定的第三方支付平台提供的付费方式购买收费服务。云歌智能会在您使用收费服务之前给予明确的提示，只有您根据提示确认同意按照前述支付方式支付费用并完成支付行为，您才能使用该等收费服务。支付行为的完成以银行等第三方支付平台生成“支付已完成”的确认通知为准。

        </p>
        <p class=""></p>
        <p class="fw">三、用户个人信息保护</p>
        <p class="">
          1、您知悉并同意，为方便您使用“灵鸽”相关服务，云歌智能将收集并存储您的必要个人信息。您的授权同意并提供相关个人信息时，请参见并仔细阅读《灵鸽隐私政策》。


        </p>
        <p class="">
          2、用户在注册“灵鸽”账号或使用本服务的过程中，可能需要填写或提交一些必要的信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如用户提交的信息不真实、不完整或不符合法律法规的规定，则用户可能无法使用本服务或在使用本服务的过程中受到限制。
        </p>
        <p class="">
         3、 尊重用户个人信息是云歌智能的一贯制度，云歌智能将采取技术措施和其他必要措施，确保用户个人信息安全，防止在本服务中收集的用户个人信息泄露、毁损或丢失。在发生前述情形或者云歌智能发现存在发生前述情形的可能时，将及时采取补救措施。

          
        </p>
        <p class="">
          4、云歌智能重视对未成年人个人隐私信息的保护。云歌智能将依赖用户提供的个人信息判断用户是否为未成年人。任何18岁以下的未成年人注册“灵鸽”账号或使用本服务应事先取得家长或其法定监护人（以下简称"监护人"）的书面同意。除根据法律法规的规定及有权机关的指示披露外，云歌智能不会使用或向任何第三方透露未成年人的聊天记录及其他个人隐私信息。除本协议约定的例外情形外，未经监护人事先同意，云歌智能不会使用或向任何第三方透露未成年人的个人隐私信息。

        </p>
        <p class=""></p>
        <p class="fw">四、内容规范</p>
        <p class="">
          1、本条所述“内容”是指用户使用本服务过程中所制作、上载、复制、发布、传播的任何内容，包括但不限于账号头像、名称、用户说明等注册信息及认证资料，或文字、语音、图片、视频、图文等发送、回复或自动回复消息和相关链接页面，以及其他使用“灵鸽”账号或本服务所产生的内容。您上传、发布的信息，需经云歌智能审核同意方可公开显示。


        </p>
        <p class="">
          2、用户不得利用“灵鸽”软件或本服务制作、上载、复制、发布、传播如下法律、法规和政策禁止的内容：


        </p>
        <p class="">(1) 反对宪法所确定的基本原则的；</p>
        <p class="">
          (2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的； 

        </p>
        <p class="">(3) 损害国家荣誉和利益的；</p>
        <p class="">(4) 煽动民族仇恨、民族歧视，破坏民族团结的；</p>
        <p class="">(5) 破坏国家宗教政策，宣扬邪教和封建迷信的；
</p>
        <p class="">(6) 散布谣言，扰乱社会秩序，破坏社会稳定的；
</p>
        <p class="">
          (7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
        </p>
        <p class="">(8) 侮辱或者诽谤他人，侵害他人合法权益的；</p>
        <p class="">
          (9) 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其他道德上令人反感的内容或违反公序良俗的；
        </p>
        <p class="">
          (10) 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其他内容的信息。

        </p>
        <p class="">
          
3、用户不得利用“灵鸽”软件或本服务制作、上载、复制、发布、传播如下干扰“灵鸽”正常运营，或侵犯其他用户或第三方合法权益的内容：

        </p>
        <p class="">(1) 含有任何性或性暗示的；</p>
        <p class="">(2) 含有辱骂、恐吓、威胁内容的；
</p>
        <p class="">(3) 含有骚扰、垃圾广告、恶意信息、诱骗信息的；

</p>
        <p class="">(4) 涉及他人隐私、个人信息或资料的；</p>
        <p class="">
          (5) 侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的；

        </p>
        <p class="">
          (6) 含有其他干扰本服务正常运营和侵犯其他用户或第三方合法权益内容的信息。
云歌智能将对上述信息进行删除，并在必要时将对制作、上传、复制、发布、传播上述信息的用户账户做封禁处理。

        </p>
        
        <p class="">
          4、“灵鸽”软件的“任务群”和“服务吧”仅用于生活服务类别的讨论，请不要在群组和吧内讨论其他无关话题，灵鸽对群组和吧内内容具有管理权限，对违规信息将分别采取删除、修改、屏蔽、关停服务等措施，对违规账号视情节分别采取警示、限制发布、暂停更新和关闭账号等措施。

        </p>
        <p class=""></p>
        <p class="fw">五、使用规则</p>
        <p class="">
          1、用户在本服务中或通过本服务所制作、上传、复制、发布、传播的任何内容并不反映或代表云歌智能的观点、立场或政策，云歌智能对此不承担任何责任。

        </p>
        <p class="">2、用户不得利用“灵鸽”软件或本服务进行如下行为：
</p>
        <p class="">
          (1) 未经允许，利用本服务进入计算机信息网络或者使用计算机信息网络资源；
        </p>
        <p class="">
          (2) 未经允许，利用本服务对计算机信息网络功能进行删除、修改或者增加；
        </p>
        <p class="">
          (3)
          未经允许，利用本服务对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加；
        </p>
        <p class="">(4) 利用本服务故意制作、传播计算机病毒等破坏性程序；
</p>
        <p class="">
          (5)
          进行任何非法的侵害云歌智能利益的行为，如贩卖比特币等虚拟货币、游戏币、外挂、道具等；
        </p>
        <p class="">(6) 其他危害计算机信息网络安全的行为;</p>
        <p class="">
          (7) 提交、发布虚假信息，或盗用他人头像或资料，冒充、利用他人名义；
        </p>
        <p class="">(8) 强制、诱导其他用户关注、点击链接页面或分享信息；</p>
        <p class="">(9) 虚构事实、隐瞒真相以误导、欺骗他人；</p>
        <p class="">(10) 利用技术手段批量建立虚假账号；
</p>
        <p class="">(11) 利用“灵鸽”账号或本服务从事任何其他违法犯罪活动；</p>
        <p class="">
          (12)
           制作、发布与以上行为相关的方法、工具，或对此类方法、工具进行运营或传播，无论这些行为是否为商业目的；

        </p>
        <p class="">
          (13) 其他违反法律法规规定、侵犯其他用户合法权益、干扰“灵鸽”正常运营或云歌智能未明示授权的行为。
          
        </p>
        <p class="">
          3、您须对使用“灵鸽”软件或本服务制作、上传、复制、发布、传播之信息的真实性、合法性、准确性、有效性等负责，与此相关的任何法律责任将由您自行承担。如因此给云歌智能或任何第三方造成损害的，您应当依法予以赔偿。

        </p>
        <p class="">
          4、在不影响用户正常使用本服务的前提下，云歌智能可能发布云歌智能、第三方供应商或合作伙伴的广告，我们将根据国家相关规定履行广告发布者的义务。云歌智能可能会与第三方的平台或媒体（“平台”）合作基于您的个人信息向您推荐个性化的服务或商品，这些个人信息包括诸如您访问本网站关联公司网站的情况及您在您的账户设置中填写的兴趣爱好。但我们不会向广告商提供您的任何个人信息。此外，我们在推荐窗内设置了屏蔽功能，您可选择屏蔽您不喜欢的广告。除法律法规明确规定外，用户应自行对依该广告信息进行的交易负责，对用户因依该广告信息进行的交易或前述广告商提供的内容而遭受的损失或损害，云歌智能不承担任何责任。

        </p>
        <p class=""></p>
        <p class="fw">六、账号管理</p>
        <p class="">
          1、“灵鸽”软件的所有权归云歌智能所有，“灵鸽”账号的所有权归云歌智能所有。用户完成申请注册手续后，获得“灵鸽”账号的使用权，该使用权仅属于初始申请注册人，不得恶意注册、赠与、借用、租用、有偿或无偿转让或售卖“灵鸽”账号或者以其他方式许可非初始申请注册人使用“灵鸽”账号。云歌智能有权对上述行为进行独立判断并处理，您应当自行承担由此产生的一切责任，同时云歌智能保留追究上述行为人法律责任的权利，且由此产生的一切责任均由您承担。云歌智能因经营需要，有权回收用户的“灵鸽”账号。

        </p>
        <p class="">
          2、用户可以更改、删除“灵鸽”账号上的个人资料、传送内容等，但云歌智能提示您注意，除非法律另有规定外，删除有关信息的同时也会删除用户储存在系统中的文字和图片，请您慎重选择并承担相应风险。您可以自行编辑个人资料中的账号、昵称、头像、简介等，但每月仅可修改三次，账号信息应遵守相关法律法规，不得含有违法和不良信息。灵鸽对账号信息内容具有管理权限，对违规信息分别采取责令改正、删除、屏蔽、关停服务等措施，对违规账号视情节分别采取警示、限制发布、暂停更新和关闭账号等措施。

        </p>
        <p class="">
          3、您的账号为您自行设置并由您保管，云歌智能在任何时候均不会主动要求您提供您的账号密码。请您妥善保管注册账号信息及账号密码的安全，建议您采取特定措施保护您的账号安全，包括但不限于安装防病毒木马软件、定期更改密码等措施。因您保管不善导致账号盗取或密码失窃并造成损失的，由您自行承担。您同意在任何情况下不使用其他用户的账号或密码。当您怀疑他人使用其账号或密码时，请立即以有效方式通知云歌智能，或向我们申请暂停或停止相关服务。我们会将前述情况同步给“灵鸽”软件，以保障您的合法权益。如需找回“灵鸽”账号信息，请按照账号找回流程提供相应的信息，并确保提供的信息合法真实有效，若提供的信息不符合要求，无法通过云歌智能安全验证，我们有权拒绝提供账号找回服务。

        </p>
        <p class="">
          4、如果您当前使用的“灵鸽”账号并非您初始申请注册的或通过云歌智能提供的其他途径获得的，但您却知悉该本账号当前的密码，您不得用该本账号登录或进行任何操作，并请您在第一时间通知云歌智能或者该“灵鸽”账号的初始申请注册人。

        </p>
        <p class="">
          5、您应遵守本协议的各项条款，并正确、适当地使用本服务，如因您违反本协议中的任何条款，云歌智能有权进行独立判断并随时采取限期改正、限制、暂停或终止您对“灵鸽”账号的使用、且根据实际情况决定是否恢复使用、追究法律责任等措施。

        </p>
        <p class="">
          6、在需要终止使用本账号服务时，符合以下条件的，您可以申请注销您的“灵鸽”账号。

        </p>
        <p class="">
          (1) 您仅能申请注销您本人的账号，并依照云歌智能的流程进行注销；
        </p>
        <p class="">
          (2) 您仍应对您在注销账号前且使用本服务期间的行为承担相应责任；
        </p>
        <p class="">
          (3)
          您应当知晓您的账号终止意味着，除法律法规另有规定外，我们将删除您的个人信息。

        </p>
        <p class=""></p>
        <p class="fw">七、提供者之责任</p>
        <p class="">
          根据有关法律规定，云歌智能在此提醒您注意，任何经由本服务公开或私下提供的（包括但不限于制作、上传、复制、发布、传播）文字、资讯、资料、音乐、照片、图形、视讯、信息或任何其它资料（以下简称“内容”），均由内容提供者承担责任。云歌智能仅为用户提供内容存储空间，无法控制经由本服务提供的内容，也无法保证该等内容的真实性、准确性或完整性。若用户提供违反本协议或中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的内容，云歌智能有权依法屏蔽或停止传送该等内容，并采取相应行动，包括但不限于暂停用户使用本服务的全部或部分，保存有关记录，并向有关机关报告。

        </p>
        <p class=""></p>
        <p class="fw">八、风险承担</p>
        <p class="">
          1、用户理解并同意，“灵鸽”仅为用户提供信息分享、传送及获取的应用，用户应为自己注册“灵鸽”账号并使用本服务的一切行为负责，包括用户所传送的任何内容以及由此产生的任何后果。用户应独立判断“灵鸽”软件及本服务中的内容，并承担因对内容的真实性、准确性、完整性或实用性的依赖而使用内容所引起的风险和损失。

        </p>
        <p class="">
          2、如果用户发现任何人违反本协议约定或以其他不当的方式使用本服务，请立即向云歌智能举报或投诉，云歌智能将依本协议约定和法律法规的规定进行处理。


        </p>
        <p class="">
          3、用户理解并同意，因业务发展需要，云歌智能保留单方面对本服务的全部或部分内容进行变更、暂停、终止或撤销的权利。
        </p>
        <p class=""></p>
        <p class="fw">九、知识产权声明</p>
        <p class="">
          1、用户在“灵鸽”软件发布的内容不得侵犯任何第三人的知识产权，未经具有相关所有权人之事先书面同意，不得以任何方式上传、发布、修改、传播或复制任何受著作权保护的材料、商标或属于其他人的专有信息。如果收到任何著作权人或其合法代表发给云歌智能的适当通知后，我们将按照法律规定在审查的基础上移除该等侵犯他人著作权的内容。

        </p>
        <p class="">
          2、除本服务中涉及广告的知识产权由相应广告商享有外，为了促进信息的分享和传播，用户同意将其在任何时间使用本服务时利用“灵鸽”账号发表上传的信息内容（包括但不限于文字、图片、视频、评论、客户咨询、各类话题等信息内容），授予云歌智能免费的、非独占地、永久地、不可撤销地、在全球范围内的使用许可，授予该等信息相关全部知识产权的使用、复制、发行、出租、展览、表演、网络信息传播权、摄制权、修改、初版、翻译、创作衍生作品等权利，并且您同意云歌智能可转授权上述权利，但您在使用本服务前对自己发布的内容已合法取得知识产权的除外。

        </p>
        <p class="">
          3、除另有特别声明外，云歌智能提供本服务时所依托软件的著作权、专利权及其他知识产权均归云歌智能所有。

        </p>
        <p class="">
          4、云歌智能在本服务中所使用的图形、文字或其组成，以及其他云歌智能标志及产品、服务名称（以下统称“云歌智能标识”），其著作权或商标权归云歌智能所有。未经云歌智能事先书面同意，用户不得将云歌智能标识以任何方式展示或使用或作其他处理，也不得向他人表明用户有权展示、使用、或其他有权处理云歌智能标识。

        </p>
        <p class="">
          5、上述及其他任何云歌智能或相关广告商依法拥有的知识产权均受到法律保护，未经云歌智能或相关广告商书面许可，用户不得以任何形式进行创作或使用相关衍生作品。

        </p>
        <p class=""></p>
        <p class="fw">十、法律责任</p>
        <p class="">
          1、如果云歌智能发现或收到他人举报或投诉用户违反本协议约定的，云歌智能有权随时对相关内容，包括但不限于用户资料、聊天记录等进行审查、删除、屏蔽，并视情节轻重对违规账号处以包括但不限于警告、账号封禁、设备封禁、功能封禁的处罚，并告知违约用户处理结果。

        </p>
        <p class="">
          2、因违反用户协议被封禁的用户不会自动解封。被封禁的用户可向“灵鸽”管理单位云歌智能提交申诉，云歌智能将对申诉进行审查，并自行合理判断决定是否解封或变更处罚措施。

        </p>
        <p class="">
          3、用户理解并同意，云歌智能有权依合理判断对违反有关法律法规之规定或本协议之约定的行为进行处罚，对违法违规的任何用户采取适当的法律行动，并依据法律法规保存有关信息向有关部门报告等。

        </p>
        <p class="">
          4、用户理解并同意，因用户违反本协议约定，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，用户应当赔偿云歌智能，并使之免受损害。

        </p>
        <p class="">
          5、用户理解并确认，云歌智能通过“灵鸽”软件向用户提供的信息、推荐的服务仅供用户参考，我们将在合理的时间之内尽力保证该等信息的准确，但无法保证其中没有任何错误、缺陷、恶意软件和病毒，对于因使用（或无法使用）“灵鸽”软件导致的任何损害，我们不承担责任（除非此类损害是由我们的故意或重大过失造成的）。此外，对于因使用或无法使用“灵鸽”软件的信息内容导致的损害，用户将给予充分的谅解，并承诺对此不追究云歌智能法律责任，但云歌智能应尽可能将上述影响减少至最低程度。

        </p>
        <p class="">
          6、用户知悉并确认，用户通过“灵鸽”软件使用本服务时云歌智能会对服务质量予以保证，云歌智能会根据法律法规的要求承担相应的法律责任。同时，为提升服务品质，保障用户服务安全，并更好的承担企业社会责任，云歌智能将通过个性化的安全保障产品为用户提供相应的服务保障。

        </p>
        <p class=""></p>
        <p class="fw">十一、不可抗力等免责事由
</p>
        <p class="">
          用户知悉并确认，在使用本服务的过程中，如发生下述情形，云歌智能不承担任何法律责任：

        </p>
        <p class="">
          1、用户理解并确认，在使用本服务的过程中，可能会遇到因不可抗力导致本服务发生中断。不可抗力是指不能预见、不能克服并不能避免且对一方或双方造成重大影响的客观事件，包括但不限于洪水、地震、风暴等自然灾害、瘟疫流行、战争、动乱、政府行为等。出现上述情况导致本服务中断时，云歌智能将努力在第一时间与相关单位配合，及时进行修复，但是由此给用户或第三方造成的损失，云歌智能及合作单位将在法律允许的范围内免责。

        </p>
        <p class="">
          2、本服务同大多数互联网服务一样，可能受到各种安全问题的侵扰，如他人利用用户的资料，造成现实生活中的骚扰；用户下载安装的其它软件或访问的其他网站中含有“特洛伊木马”等病毒，威胁到用户的计算机信息和数据的安全，继而影响本服务的正常使用等等。用户应加强信息安全及使用者资料的保护意识，要注意加强密码保护，以免遭致损失和骚扰。

        </p>
        <p class="">
          3、用户理解并确认，本服务存在任何因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机以及其他任何技术、互联网络、通信线路、信息安全管理措施等原因造成的本服务中断或不能满足用户要求的风险，因此导致的用户或第三方任何损失，云歌智能不承担任何责任。

        </p>
        <p class="">
          4、用户理解并确认，在使用本服务过程中存在来自任何他人的包括误导性的、欺骗性的、威胁性的、诽谤性的、令人反感的或非法的信息，或侵犯他人权利的匿名或冒名的信息，以及伴随该等信息的行为，因此导致的用户或第三方的任何损失，云歌智能不承担任何责任。

        </p>
        <p class="">
          5、用户理解并确认，云歌智能为了维护“灵鸽”的正常运行，需要定期或不定期地对“灵鸽”或相关的设备进行检修或者维护，如因此类情况而造成服务在合理时间内的中断，云歌智能无需为此承担任何责任，但云歌智能应事先进行通告。

        </p>
        <p class="">
          6、用户理解并确认，云歌智能在“灵鸽”上向用户提供的信息、推荐的服务仅供用户参考，我们将在合理的时间之内尽力保证该等信息的准确，但无法保证其中没有任何错误、缺陷、恶意软件和病毒，对与因使用（或无法使用）“灵鸽”导致的任何损害，我们不承担责任。此外，对于因使用或无法使用灵鸽的信息内容导致的损害，用户将给予充分的谅解，并承诺对此不追究云歌智能法律责任，但云歌智能应尽可能将上述影响减少至最低程度。

        </p>
        <p class="">
          7、云歌智能依据法律法规、本协议约定获得处理违法违规或违约内容的权利，该权利不构成云歌智能的义务或承诺。

        </p>
        <p class="">
          8、云歌智能依照法律规定履行基础保障义务。在任何情况下，云歌智能均不对任何除此之外的间接性、后果性、惩罚性、偶然性、特殊性或刑罚性的损害，包括因用户使用“灵鸽”软件或本服务而遭受的利润损失，承担责任（即使云歌智能已被告知该等损失的可能性亦然）。

        </p>
        <p class=""></p>
        <p class="fw">十二、服务的变更、中断、终止</p>
        <p class="">
          1、鉴于网络服务的特殊性，云歌智能有权变更、中断或终止部分或全部的服务（包括收费服务）。云歌智能会在变更、中断或终止之前通知用户，并向拟受影响的用户提供替代性（若变更、中断或终止的是免费服务）或等值（若变更、中断或终止的是收费服务）的服务；如用户不愿意接受替代性的服务，且该用户已经向云歌智能支付费用，云歌智能会按照该用户实际使用服务的情况扣除相应金额之后将剩余的费用退还用户。

        </p>
        <p class="">
          2、如发生下列任何一种情形，云歌智能有权变更、中断或终止向用户提供的免费服务或收费服务，而无需对用户或任何第三方承担任何责任：

        </p>
        <p class="">
        
          (1) 根据法律规定用户应提交真实信息，而用户提供的个人资料不真实、或与注册时信息不一致又未能提供合理证明；

        </p>
        <p class="">(2) 用户违反相关法律法规的规定或本协议的约定；
</p>
        <p class="">(3) 法律规定或有权机关的要求；</p>
        <p class="">(4) 出于安全的原因或其他必要的情形。</p>
        <p class=""></p>
        <p class="fw">十三、其他</p>
        <p class="">
          1、云歌智能郑重提醒用户注意本协议中免除云歌智能责任和限制用户权利的条款，请用户仔细阅读，自主考虑风险。未成年人应在法定监护人的陪同下阅读本协议。

        </p>
        <p class="">
          2、本协议的效力、解释及纠纷的解决，适用于中华人民共和国法律。若用户和云歌智能之间发生任何纠纷或争议，首先应友好协商解决，协商不成的，用户同意将纠纷或争议提交云歌智能住所地深圳市南山区有管辖权的人民法院管辖。

        </p>
        <p class="">
          3、本协议的任何条款无论因何种原因无效或不具可执行性，其余条款仍有效，对双方具有约束力。

        </p>
        <p class="">
          4、如果您想与我们联系了解有关隐私权声明、本协议中的任何事项，包括对您的个人信息行使权利、查询、投诉，或选择不接收直接推销信息，或对我们处理您的个人信息有任何疑问，请按以下详细方式和我们联系。

        </p>
        <p class="">电话：0755-86662451
</p>
        <p class="">邮箱：tousu@ringle.com</p>
        <p class=""></p>
      </section>
    </div>

    <ad-footer style="margin-top: 100px"></ad-footer>
  </div>
</template>

<script>
// 用户使用协议
export default {
  name: "adProtocolComponent",
  data() {
    return {};
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
  .ad-protocol {
    padding-top: 50px !important;
    .content-container {
      max-width: 100% !important;
      padding: 20px !important;
      border-radius: 0px !important;
      .title {
        width: 100% !important;
        padding: 10px 0px 20px 0px !important;
        font-size: 18px !important;
      }
      .main {
        width: 100% !important;
        p {
          font-size: 13px !important;
        }
      }
    }
  }
}
.ad-protocol {
  width: 100%;
  padding-top: 130px;
  box-sizing: border-box;
  .content-container {
    max-width: 1180px;
    background: #ffffff;
    border-radius: 20px;
    margin: 0px auto;
    padding-bottom: 100px;
    .title {
      width: 948px;
      margin: 0px auto;
      font-size: 30px;
      font-family: "tj";
      font-weight: 600;
      color: #222222;
      line-height: 42px;
      padding: 100px 0px 59px 0px;
    }
    .main {
      width: 948px;
      margin: 0px auto;
      p {
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 400;
        color: #222222;
        line-height: 32px;
      }
    }
  }
}
</style>